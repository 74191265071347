.quoteDiv {
    text-align: center;
    width: 100%;
    background-image: url('../img/knobss.jpg');
    height: fit-content;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    letter-spacing: 1px;
    min-width: 100%;
    max-width: 100%;
}

.quoteInnerDiv {
    max-width: 1000px;
    margin: auto;
    padding: 25px;
}

.quoteH2 {
    font-size: 60px;
    margin: 25px 0;
    letter-spacing: 2px;
}

.quoteH3 {
    font-size: 30px;
    margin: 15px 0;
    font-weight: 450;
}

.quoteP {
    font-size: 20px;
    font-weight: 250;
}