body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  min-height: 100%;
  min-width: 100%;
}

.center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.yellow {
  color: rgb(254, 196, 88);
}

.white {
  color: white;
}

button:hover {
  cursor: pointer;
}