.contactDiv {
    padding: 40px 0;
    min-width: 100%;
    background-image: url('../img/record.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.contactInner {
    max-width: 600px;
    margin: auto;
    box-shadow: 5px 5px 20px 1px rgb(223, 228, 233);
    padding: 2px;
    background-color: rgb(249, 249, 249);
}

.contactForm {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(240, 240, 240);
    padding: 15px;
    background-color: white;
}

.contactForm label {
    margin-top: 10px;
    width: fit-content;
}

.contactForm input {
    padding: 4px;
}

.hoverBorder:hover {
    border: 2px solid rgb(77, 77, 77);
  }

.formButton {
    margin: 20px 0;
    height: 30px;
    border: 1px solid darkgrey;
    background-color: rgb(218, 142, 0);
}

.formButton:hover {
    background-color: orange;
    box-shadow: 1px 1px 20px 5px rgb(255, 255, 255);
}

#messageLabel {
    margin-top: 20px;
}

textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    padding: 4px;
    margin-bottom: 20px;
}