.mixBannerDiv {
    display: flex;
    justify-content: center;
    background: url('../img/tempPT.jpg') top center;
    height: 700px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
}

.bannerMessageDiv {
    margin: auto;
    display: flex;
    flex-direction: column;
}

@keyframes bannerMessageFadeIn {
    0% {opacity: 0;}
    50% {opacity: 0.5;}
    100% {opacity: 1;}
}

#bannerMessage1,
#bannerMessage2,
#bannerButton{
    opacity: 0.0;
    animation-name: bannerMessageFadeIn;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

#bannerMessage1, #bannerMessage2 {
    font-size: 70px;
    font-weight: lighter;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    padding-bottom: 10px;
    text-shadow: 1px 1px 10px rgb(227, 227, 227);
    margin: 20px 0;
}

#bannerMessage2{animation-delay: 0.2s;}

#bannerButton {
    margin: auto;
    margin-top: 150px;
    font-size: 30px;
    padding: 7px 30px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(218, 142, 0);
    width: fit-content;
    box-shadow: 1px 1px 10px 1px rgb(206, 206, 206);
    animation-delay: 0.4s;
}

#bannerButton:hover {
    background-color: orange;
    box-shadow: 1px 1px 20px 5px rgb(255, 255, 255);
}

@media screen and (max-width: 500px) {
    #bannerMessage1, #bannerMessage2 {
        font-size: 50px;
    }
}
@media screen and (max-width: 800px) {
    #bannerMessage1, #bannerMessage2 {
        font-size: 60px;
        padding-bottom: 10px;
    }
}