header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    max-width: 900px;
    margin: auto;
}

#socraticLogo {
    margin: 0;
    height: 151px;
    width: 407.5px;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
    height: fit-content;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 400px;
}

.navLink {
    width: 100%;
    padding: 5px 2px;
    text-align: center;
    height: fit-content;
    background-color: rgb(41, 41, 41);
    color: white;
}

.navLink:hover {
    background-color: rgb(80, 80, 80);
}

nav a:link {
    text-decoration: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    letter-spacing: 1px;
    font-size: 20px;
}

@media screen and (max-width: 800px){
    header {
        flex-direction: column;
        padding: 0;
    }
    #socraticLogo {
        margin: auto;
        height: 113.3px;
        width: 305.6px;
    }
    nav {
        min-width: 100%;
        max-width: 100%;
        position: relative;
    }
}